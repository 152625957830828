<template>
  <div class="row">
    <div class="col-12">
      <b-card>
        <div class="row">
          <!-- <div class="col-12 d-flex justify-content-start">
            <div class="row">
              <div class="col-12 col-md-3">
                <b-input type="text" placeholder="Search" />
              </div>
            </div>
          </div> -->
          <div class="col-12">
            <b-tabs pills vertical v-model="activeTab">
              <b-tab :title="company.title" v-for="(company, ck) in thirdParties" :key="`tpk_${ck}`">
                <ThirpartyConfigForm
                  :key="`ck${ck}_${thirdParty.unique_key}`"
                  :thirdpartyConfig="thirdpartyConfig"
                  :service="service"
                  :thirdParty="thirdParty"
                  @itemAdded="itemAdded" 
                  @itemUpdated="itemUpdated" 
                />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app';
import { isEmpty } from '@/utilities';
import { mapActions } from 'vuex';
import ThirpartyConfigForm from '@/views/thirdparty/ThirpartyConfigForm.vue';

export default {
  name: "ThirpartyConfigServiceWise",
  components: {
    ThirpartyConfigForm
  },
  props: {
    thirdpartyConfigs: Array,
    service: Object,
    thirdParties: Object
  },
  data: () => ({
    activeTab: 0
  }),
  computed: {
    activeUniqueKey: {
      get(){
        const thirdPartyKeys = Object.keys(this.thirdParties);
        return thirdPartyKeys[this.activeTab];
      }
    },
    thirdParty: {
      get(){
        return this.thirdParties[this.activeUniqueKey];
      }
    },
    thirdpartyConfig: {
      get() {
        const thirdpartyConfig = this.thirdpartyConfigs.find(i => i.company_unique_key.toLowerCase() == this.thirdParty.unique_key.toLowerCase());
        return thirdpartyConfig ?? {};
      }
    },
  },
  methods: {
    isEmpty,
    itemAdded(item) {
      this.$emit('itemAdded', item)
    },
    itemUpdated(item) {
      this.$emit('itemUpdated', item)
    },
  },
}
</script>
<style>
.text-area-22 {
  font-size: 1.2rem !important;
}
</style>