<template>
  <section class="ecommerce-application">
    <div class="row">
      <div class="col-12">
        <b-tabs v-model="activeTab" content-class="mt-3" align="center">
          <b-tab v-for="(service, sk) in services" :key="`tab_${sk}`">
            <template #title>
              <b-button variant="outline-dark" size="sm">
                <b-img height="25" class="cmr-1" :src="service.logo_url" />
                {{ $t(service.label) }}
              </b-button>
            </template>
            <ThirpartyConfigServiceWise 
              v-if="!isEmpty(thirdParties)"
              :thirdpartyConfigs="thirdpartyConfigs" 
              :service="service" 
              :thirdParties="thirdParties"
              @itemAdded="itemAdded" 
              @itemUpdated="itemUpdated" 
            />
            <div v-else>
              <div class="alert alert-danger px-1 py-2">
                No third party data available for {{ service.label }}.
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </section>
</template>

<script>
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app';
import { isEmpty } from '@/utilities';
import { mapActions } from 'vuex';
import ThirpartyConfigServiceWise from '@/views/thirdparty/ThirpartyConfigServiceWise.vue';
export default {
  name: "ThirpartyConfig",
  components: {
    ThirpartyConfigServiceWise
  },
  data: () => ({
    thirdparties: [],
    services: [],
    thirdparty_configs: [],
    activeTab: 0,
  }),
  computed: {
    currentService: {
      get(){
        const serviceKeys = Object.keys(this.services);
        return serviceKeys[this.activeTab];
      }
    },
    thirdpartyConfigs: {
      get() {
        return this.thirdparty_configs.filter(i => i.service === this.currentService).sort((b, a) => new Date(b.created_at) - new Date(a.created_at));
      }
    },
    thirdParties: {
      get() {
        const thirdPartyKeys =  Object.keys(this.thirdparties);
        return this.thirdparties[thirdPartyKeys[this.activeTab]]
      }
    }
  },
  methods: {
    ...mapActions('thirdpartyConfig', ['getThirdpartyConfigs']),
    isEmpty,
    getData() {
      showLoader()
      this.getThirdpartyConfigs().then(r => {
        this.thirdparties = r.thirdparties
        this.services = r.services
        this.thirdparty_configs = r.thirdparty_configs
        hideLoader();
      }).catch(e => {
        hideLoader();
      })
    },
    itemAdded(item) {
      this.thirdparty_configs.push(item)
    },
    itemUpdated(item) {
      this.thirdparty_configs = this.thirdparty_configs.map(i => i.id == item.id ? item : i)
    },
  },
  mounted() {
    this.getData()
  }

}
</script>
<style>
.custom-disabled:disabled {
  color: #000 !important;
}
</style>